<assist-dropdownlist
    [data]="languageItems"
    [formControl]="currentLanguageControl"
    [popupSettings]="{
        popupClass: 'language-popup'
    }"
    class="language-dropdown"
    textField="text"
    valueField="text"
    [listHeight]="400"
    data-cy="select-language"
>
    <ng-template let-dataItem valueTemplate>
        <img [src]="'assets/images/flags/' + dataItem?.flag" class="flag" height="30" width="30" />
    </ng-template>
    <ng-template itemTemplate let-dataItem>
        <img [src]="'assets/images/flags/' + dataItem.flag" class="flag" height="30" width="30" />
        {{ dataItem.text }}
    </ng-template>
</assist-dropdownlist>
<router-outlet></router-outlet>
